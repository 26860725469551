<template>
  <main>
    <NodeTopicPage v-if="node" v-bind="node" />
  </main>
</template>

<script setup lang="ts">
definePageMeta({
  path: '/:prefix(themen|topics|themes|temi)/:slug(.*)',
})

const { entity: node } = await useDrupalRouteQuery('topic')
await renderPageDependencies()
</script>
